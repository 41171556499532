import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa'

import Styles from './sub-page-client-points.module.scss';

export default ( props ) => (

  <ul  className={Styles.list}>
    {props.points.map(( value, index ) =>  
      <li
        key={index}
        className={Styles.line}
      >
        <FaArrowCircleRight  /> <p>{value}</p>
      </li>
    )}
  </ul>
); 