import React from 'react';

import Styles from './sub-page-client-image.module.scss';

export default ( props ) => (

  <section className={Styles.section}>
    <div className="container">
      {props.image}
    </div>
  </section>
)
