import React from 'react';

import Styles from './sub-page-client-results.module.scss';

export default ( props ) => (

  <ul  className={Styles.list}>
    {props.result.map(( value, index ) =>  
      <li
        key={index}
        className={Styles.line}
      >
        <h3 className={Styles.title}>{value.title}</h3>
        <p className={Styles.subText}>{value.sub}</p>
      </li>
    )}
  </ul>
); 