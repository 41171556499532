import React from 'react';

import Points from '../sub-page-client-points/sub-page-client-points';
import Results from '../sub-page-client-results/sub-page-client-results';
import Styles from './sub-page-client-container.module.scss';

export default ({ section, title, description, points, result }) => (

  <section 
    className={`${Styles.section} ${section ? Styles.backgroundGreen : ''}`}
  >
    <div className="container">
      <div className={Styles.innerContainer}>
        <h2 className={Styles.title}>
          {title}
        </h2>
        {description ? <p className={Styles.text}>{description}</p> : ''}
        {points ? <Points points={points}/> : ''}
        {result ? <Results result={result}/> : ''}
      </div>
    </div>
  </section>
);
