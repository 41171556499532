import React from 'react';

import Styles from './sub-page-client-company.module.scss';

export default ( props ) => (

  <section className={Styles.section}>
    <div className="container">
      <div className={Styles.companyContainer}>
        <div className={Styles.company}>
          <div className={Styles.logo}>{props.logo}</div>
          <div className={Styles.buttonContainer}>
            <a 
              href={props.link}
              className="button__content button--pulse"
            >
              Visit Site
            </a>
          </div>
        </div>
        <div className={Styles.textContainer}>
          <h2 className={Styles.textTitle}>{props.titleDescription}</h2>
          <p className={Styles.text}>{props.description}</p>
        </div>
      </div>
      <div className={Styles.companyContainer}>
        <div className={Styles.serviceContainer}>
          <h2 className={Styles.serviceTitle}>Services</h2>
          <ul className={Styles.serviceList}>
            {props.services.map(( value, index ) => 
              <li 
                key={index}
                className={Styles.service}
              >
                {value}
              </li> 
            )}
          </ul>
        </div>
        <div className={Styles.techContainer}>
          <h2 className={Styles.techTitle}>Tech</h2>
          <ul className={Styles.techList}>
            {props.tech.map(( value, index ) => 
              <li 
                key={index}
                className={Styles.tech}
              >
                {value}
              </li> 
            )}
          </ul>
        </div>
      </div>
    </div>
  </section>
)
